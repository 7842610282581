import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { BannerContainer, BannerWrapper } from "../global/common/containers"
import { MainTitle } from "../global/common/text"
import { greenBox, fadedGreenBox } from "../global/common/ornaments"

import { SocialMedia } from "../global/SocialMedia"
import { Reviews } from "../global/Reviews"
import { Button } from "../global/atoms/Button"

import Nav from "../global/nav/Nav"
import ButtonGroup from "../global/atoms/ButtonGroup"
import SmallLogos from "../global/common/logos"

import SiteLanguage from "../../helpers/siteLanguage"

const ContentColumn = styled.div`
  max-width: 100%;
  width: 680px;
  padding-right: 90px;

  @media ${maxD.laptopL} {
    width: 540px;
    padding-right: 70px;
  }

  @media ${maxD.laptop} {
    width: 480px;
  }

  @media ${maxD.mobileL} {
    padding-right: 0;
  }
`

const ContentColumnInner = styled.div`
  padding-left: 80px;

  @media ${maxD.laptopL} {
    padding-left: 50px;
  }

  @media ${maxD.laptop} {
    padding-left: 0;
  }
`

const ImagesColumn = styled.div`
  position: relative;
  width: calc(100% - 680px);

  @media ${maxD.laptopL} {
    width: calc(100% - 540px);
  }

  @media ${maxD.laptop} {
    width: calc(100% - 480px);
  }

  @media ${maxD.tablet} {
    width: 100%;
  }
`

const BannerImage = styled(GatsbyImage)`
  position: absolute;

  max-width: 100%;
  width: 408px;
  height: 510px;

  @media ${maxD.desktop} {
    width: calc(408px * 0.9);
    height: calc(510px * 0.9);
  }

  @media ${maxD.tablet} {
    position: relative;
  }

  @media ${maxD.mobileL} {
    width: 100%;
    height: 350px;
  }
`

const BottomImage = styled(BannerImage)`
  left: 0;
  bottom: 0;

  z-index: 2;

  ${greenBox}

  &::after {
    top: -45px;
    left: 180px;

    @media ${maxD.laptopL} {
      left: 60px;
    }
  }

  @media ${maxD.laptop} {
    display: none;
  }
`

const TopImage = styled(BannerImage)`
  right: 0;
  bottom: 210px;

  ${fadedGreenBox}

  &::after {
    left: 130px;
    bottom: -45px;

    @media ${maxD.laptopL} {
      left: 240px;
    }
  }

  @media ${maxD.laptop} {
    bottom: 120px;

    &::after {
      left: 70px;
    }
  }

  @media ${maxD.tabletL} {
    display: none;
  }
`

const SocialMediaContainer = styled.div`
  @media ${maxD.tabletL} {
    display: none;
  }
`

function HomeHeader({ pageContext, data }) {
  const header = {
    gray: data.homeBannerTitle.textGray,
    green: data.homeBannerTitle.textGreen,
  }

  const buttons = [
    {
      title: data.homeBannerButtons.homeBannerButton1 && data.homeBannerButtons.homeBannerButton1.title,
      url: data.homeBannerButtons.homeBannerButton1 && data.homeBannerButtons.homeBannerButton1.url,
      target: data.homeBannerButtons.homeBannerButton1 && data.homeBannerButtons.homeBannerButton1.target,
    },
    {
      title: data.homeBannerButtons.homeBannerButton2.title,
      url: data.homeBannerButtons.homeBannerButton2.url,
      target: data.homeBannerButtons.homeBannerButton2.target,
    },
  ]

  const images = {
    top: {
      url: data.homeBannerImages.homeBannerImage1.localFile.childImageSharp
        .gatsbyImageData,
      alt: data.homeBannerImages.homeBannerImage1.altText,
      altPl: data.homeBannerImages.homeBannerImage1.atttachement_pl?.altTextPl
    },
    bottom: {
      url: data.homeBannerImages.homeBannerImage2.localFile.childImageSharp
        .gatsbyImageData,
      alt: data.homeBannerImages.homeBannerImage2.altText,
      altPl: data.homeBannerImages.homeBannerImage2.atttachement_pl?.altTextPl
    },
  }

  return (
    <BannerContainer
      style={{
        "--laptop-padding": "220px 0 0",
      }}
    >
      <Nav pageContext={pageContext} />
      <BannerWrapper relative flex alignItems="fe">
        <ContentColumn>
          <ContentColumnInner>
            <MainTitle as="h1" style={{ lineHeight: "1.5" }}>
              {`${header.gray} `}
              <br />
              <span>{header.green}</span>
            </MainTitle>
            <ButtonGroup>
              {
                buttons[0].title ? <Button to={buttons[0].url}>{buttons[0].title}</Button> : ''
              }
              <Button to={buttons[1].url} theme="orange">
                {buttons[1].title}
              </Button>
            </ButtonGroup>
          </ContentColumnInner>
          <Reviews />
          <SmallLogos logos={data.homeBannerLogos} />
        </ContentColumn>
        <ImagesColumn>
          <TopImage
            image={images.top.url}
            alt={(SiteLanguage() !== "en" && images.top.altPl) ? images.top.altPl : images.top.alt}
            style={{ overflow: "visible" }}
          />
          <BottomImage
            image={images.bottom.url}
            alt={(SiteLanguage() !== "en" && images.bottom.altPl) ? images.bottom.altPl : images.bottom.alt}
            style={{ overflow: "visible" }}
          />
        </ImagesColumn>
        <SocialMediaContainer>
          <SocialMedia theme="green" style={{ position: "absolute" }} />
        </SocialMediaContainer>
      </BannerWrapper>
    </BannerContainer>
  )
}

export { HomeHeader }
