import React from "react"

import MasonryBoxes from "../global/atoms/MasonryBoxes"
import SectionWithIndentedTitle from "../global/SectionWithIndentedTitle"

import SiteLanguage from "../../helpers/siteLanguage"

const WhyUs = ({ data }) => {
  const title = data.indentedSectionTitle

  const image = {
    url: data.indentedSectionImage?.localFile.childImageSharp.gatsbyImageData,
    alt: (SiteLanguage() !== "en" && data.indentedSectionImage?.atttachement_pl?.altTextPl) ? data.indentedSectionImage?.atttachement_pl?.altTextPl : data.indentedSectionImage?.altText
  }

  const button = {
    title: data.indentedSectionButton?.title,
    url: data.indentedSectionButton?.url,
    target: data.indentedSectionButton?.target,
  }

  const boxes = data.indentedSectionList

  return (
    <SectionWithIndentedTitle
      title={title}
      image={image}
      containerStyle={{ "--background-color": "var(--light-gray)" }}
    >
      <MasonryBoxes boxes={boxes} button={button} />
    </SectionWithIndentedTitle>
  )
}

export { WhyUs }
