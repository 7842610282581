import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"

import { FooterContainer } from "../../components/global/common/containers"

import Testimonials from "../../components/global/Testimonials"
import Partners from "../../components/global/Partners"
import CallToAction from "../../components/global/CallToAction"
import Footer from "../../components/global/Footer"

import { HomeHeader } from "../../components/home-page/HomePageHeader"
import { WhyUs } from "../../components/home-page/WhyUs"
import { WhatWeDo } from "../../components/home-page/WhatWeDo"
import { SimpleSteps } from "../../components/home-page/SimpleSteps"

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfHomepage {
        homeStepsTitle
        homeStepsButton {
          target
          title
          url
        }
        homeStepsList {
          header
          description
        }
        homeBannerTitle {
          textGray
          textGreen
        }
        homeBannerButtons {
          homeBannerButton2 {
            target
            title
            url
          }
          homeBannerButton1 {
            target
            title
            url
          }
        }
        homeWhatWeDoTitle {
          textGray
          textGreen
        }
        homeWhatWeDoDescription
        homeWhatWeDoList {
          header
          icon
          description
          fieldGroupName
          homeWhatWeDoListButton {
            target
            title
            url
          }
        }
        homeBannerImages {
          homeBannerImage2 {
            altText
            atttachement_pl {
              altTextPl
            }
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          homeBannerImage1 {
            altText
            atttachement_pl {
              altTextPl
            }
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        homeBannerLogos {
          altText
          atttachement_pl {
            altTextPl
          }
          id
          localFile {
            publicURL
          }
        }
        indentedSectionTitle
        indentedSectionList {
          description
          header
        }
        indentedSectionImage {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        indentedSectionButton {
          target
          title
          url
        }
        testimonialsTitle
        testimonials {
          ... on WpTestimonial {
            id
            acfSingleTestimonials {
              content
              testimonialname
              position
              link {
                target
                title
                url
              }
              logo {
                altText
                atttachement_pl {
                  altTextPl
                }
                localFile {
                  publicURL
                }
              }
              testimonialimage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ pageContext, data: { wpPage } }) => {
  const { seo } = wpPage

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <HomeHeader data={wpPage.acfHomepage} pageContext={pageContext} />
      <Testimonials data={wpPage.acfHomepage} />
      <WhyUs data={wpPage.acfHomepage} />
      <WhatWeDo data={wpPage.acfHomepage} />
      <SimpleSteps data={wpPage.acfHomepage} />
      <Partners />
      <FooterContainer>
        <CallToAction data={wpPage.acfCallToAction} />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default IndexPage
